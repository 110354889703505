import React from "react";
import ContactForm from "./ContactForm/ContactForm";

const HomeContact = () => {
  return (
    <>
      <section className="contact__area">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-xl-12">
              <div className="contact__map d-flex flex-column align-items-center justify-content-center">
                {/* <iframe title='contact' src="https://maps.google.com/maps?hl=en&amp;q=Dhaka+()&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed"></iframe> */}
                <ContactForm />
                <div className="contact__branches mt-75">
                  <h3 className="mb-30">Nuestras oficinas</h3>
                  <ul className="d-flex flex-wrap align-items-center">
                    <li>Mar del Plata</li>
                    <li className="separator">-</li>
                    <li>Villa Gesell</li>
                    <li className="separator">-</li>
                    <li>Azul</li>
                    <li className="separator">-</li>
                    <li>Pinamar</li>
                    <li className="separator">-</li>
                    <li>Olavarría</li>
                    <li className="separator">-</li>
                    <li>Tres Arroyos</li>
                    <li className="separator">-</li>
                    <li>Chascomús</li>
                    <li className="separator">-</li>
                    <li>Dolores</li>
                    <li className="separator">-</li>
                    <li>Castelli</li>
                    <li className="separator">-</li>
                    <li>Vidal</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeContact;
