import CircleItem from "./CircleItem/CircleItem";
import './CircleItems.scss'


const CircleItems = ({items}) => {
  return (
    <>
      <section className="container flex-wrap d-flex justify-content-center pb-50 pt-50">
         {items.map(item => {
            return <CircleItem key={item} item={item}/>
         })}
      </section>
    </>
  );
};

export default CircleItems;
