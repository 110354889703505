import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

const HomeAbout = () => {
  return (
    <>
      <section className="about__area pb-200 pt-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 g-0 pr-0 col-lg-6">
              <div className="about__thumb m-img">
                <img src="assets/img/about/about-3.png" alt="" />
                {/* <div className="about__shape">
                              <img src="assets/img/about/red-shape.png" alt=""/>
                           </div> */}
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
              <div className="about__content">
                <div className="section__title mb-25">
                  <span>Productor</span>
                  <h2>Tenemos una oportunidad para vos</h2>
                </div>
                <p>
                  Nuestra misión es impulsar el éxito de nuestros productores
                  asociados. Estamos comprometidos a brindarles a nuestros
                  socios las herramientas y el apoyo necesarios para alcanzar
                  sus objetivos. Potenciá tu carrera. Unite a nosotros y
                  experimentá la diferencia de trabajar con un equipo
                  comprometido con tu éxito.
                </p>
                <div className="about__list">
                  <ul>
                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        Mejores polizas
                      </span>
                    </li>
                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        Mejores precios
                      </span>
                    </li>
                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        Atención personalizada y capacitación
                      </span>
                    </li>
                  </ul>
                </div>
                <Link to="/productores" className="z-btn">
                  Conocé más
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
