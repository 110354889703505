import "./HomeProductors.scss";
import HomeCounterArea from "../HomeCounterArea/HomeCounterArea";
import HomeAbout from "../HomeAbout/HomeAbout";

const HomeProductors = () => {
  return (
    <div className="productors">
      <HomeCounterArea />
      <HomeAbout />
    </div>
  );
};

export default HomeProductors;
