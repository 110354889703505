import React from "react";
import HomeOneHeader from "./HomeOneHeader/HomeOneHeader";
import HomeHeroSlider from "./HomeHeroArea/HomeHeroSlider";
import Footer from "./Footer/Footer.js";
import PageHelmet from "../../components/shared/PageHelmet";
import CircleItems from "./CircleItems/CircleItems";
import ContactForm from "./HomeContact/ContactForm/ContactForm";

const Companies = () => {
  const items = [
    "art",
    "flota",
    "comercio",
    "caución",
    "respons. civil",
    "transporte",
    "consorcio",
    "agro",
    "medicina prepaga",
  ];
  return (
    <>
      <PageHelmet pageTitle="Home Page" />
      <HomeOneHeader />
      <HomeHeroSlider
        title="Seguros para empresas"
        subtitle="Especializados en identificar y administrar riesgos, evaluar escenarios y ofrecer la mejor alternativa costo-beneficio, poniendo especial foco en el servicio al cliente"
        image="home_slider_companies"
        height="650"
      />
      <CircleItems items={items} />
      <ContactForm />
      <Footer />
    </>
  );
};

export default Companies;
