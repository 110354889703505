import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import HomeFour from "./pages/HomeFour/Home";
import Companies from "./pages/HomeFour/Companies";
import Productors from "./pages/HomeFour/Productors";
import People from "./pages/HomeFour/People";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        <Routes>
          <Route path="/" element={<HomeFour />} />
          <Route path="/empresas" element={<Companies />} />
          <Route path="/productores" element={<Productors />} />
          <Route path="/personas" element={<People />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
