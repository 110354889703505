import React from "react";
// import { CgArrowLongRight } from 'react-icons/cg';
import './HomeSingleFeature.scss'

const HomeSingleFeature = ({ last = false, title }) => {
  return (
    <>
      <div >
        <div className={`features__item text-center ${last ? 'last' : ''}`}>
          <div
            className="features__thumb"
            style={{
              background: `white`,
              backgroundRepeat: "no-repeat",
              width: "100%",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
          <div className="features__content">
            {/* <div className="features__icon">
                     <i> {icon} </i>
                  </div> */}
            <h3> {title} </h3>
            {/* <div className="features__btn">
                     <Link to="/servicesDetails" className="link-btn">
                        <i > <CgArrowLongRight /> </i>
                        <i > <CgArrowLongRight /> </i>
                     </Link>
                  </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeSingleFeature;
