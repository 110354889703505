import React from 'react';
import SingleTeam from '../../../components/SingleTeam/SingleTeam';

const HomeTeams = () => {
   return (
      <>
         <section className="team__area pt-30 pb-80">
            <div className="container">
               <div className="row align-items-center mb-55">
                  <div className="col-xl-6 col-lg-8 col-md-8 col-sm-8">
                     <div className="section__title section__title-3 mb-30">
                        <span>Nuestro equipo</span>
                        <h2>Un equipo integrado por profesionales en el área</h2>
                     </div>
                  </div>
                  {/* <div className="col-xl-6 col-lg-4 col-md-4 col-sm-4">
                     <div className="team__more d-flex justify-content-sm-end mb-85">
                        <Link to="/team" className="z-btn z-btn-border">View all<i > <CgArrowLongRight /> </i></Link>
                     </div>
                  </div> */}
               </div>
               <div className="row align-items-stretch justify-content-center">


                  <SingleTeam image="francoantonino" name="Franco Antonino" title="Director Ejecutivo" linkedin="https://www.linkedin.com/in/franco-antonino-8a1055156" />
                  <SingleTeam image="javiermanonis" name="Javier Mañonis" title="Associate Consultant" linkedin="https://www.linkedin.com/in/javier-ma%C3%B1onis-8b9380161"/>
                  <SingleTeam image="marianomasola" name="Mariano Masola" title="Responsable Corporativo" linkedin="https://www.linkedin.com/in/mariano-masola-9b8665123"/>
                  <SingleTeam image="manuelgonzalez" name="Manuel González Mallistreli" title="Responsable RRHH" linkedin="https://www.linkedin.com/in/manuel-gonz%C3%A1lez-mallistreli-473923207"/>
                  <SingleTeam image="franciscohuici" name="Francisco Huici" title="Responsable Administración" linkedin=""/>
                  <SingleTeam image="florenciarey" name="Florencia Rey Méndez" title="Directora Vida y Retiro" linkedin="https://www.linkedin.com/in/maria-florencia-rey-mendez-27366629a/"/>
                  <SingleTeam image="barbaravillalba" name="Bárbara Villalba" title="Ejecutiva Vida y Retiro" linkedin="https://www.linkedin.com/in/bvillalba/" />
                  <SingleTeam image="marianamusic" name="Mariana Music" title="Ejecutiva de Ventas" linkedin="https://www.linkedin.com/in/mariana-music-112732233 " />
                  

                  {/* <div className="col-xl-3 col-lg-4 col-md-6">
                     <div className="team__item team__item-add p-relative text-center fix mb-30" >
                        <div className="team__add">
                           <Link to="/team"><i > <FaPlus/> </i></Link>
                        </div>
                     </div>
                  </div> */}
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTeams;