import React from "react";
import HomeOneHeader from "./HomeOneHeader/HomeOneHeader";
import HomeHeroSlider from "./HomeHeroArea/HomeHeroSlider";
import HomeFeatures from "./HomeFeatures/HomeFeatures";
import HomeServices from "./HomeServices/HomeServices";
import HomeTeams from "./HomeTeams/HomeTeams";
import HomeBrands from "./HomeBrands/HomeBrands";
import HomeContact from "./HomeContact/HomeContact";
import Footer from "./Footer/Footer.js";
import HomeProductors from "./HomeProductors/HomeProductors";
import PageHelmet from "../../components/shared/PageHelmet";

const Home = () => {
  return (
    <>
      <PageHelmet pageTitle="Home Page" />
      <HomeOneHeader />
      <HomeHeroSlider
        title="Fusionamos la experiencia y la innovación para redefinir el mundo de los seguros"
        image="home_slider_background"
        height="720"
      />
      <HomeFeatures />
      <HomeServices />
      <HomeProductors />
      <HomeTeams />
      <HomeBrands />
      <HomeContact />
      <Footer />
    </>
  );
};

export default Home;
