import React from "react";
import HomeSingleFeature from "./HomeSingleFeature/HomeSingleFeature";
import { NavLink } from "react-router-dom";

const HomeFeatures = () => {
  return (
    <>
      <section
        className="features__area pb-100 wow fadeInUp"
        data-wow-delay=".5s"
      >
        <div className="container">
          <div className="features__inner fix">
            <div className="row g-0 ">
              <NavLink to="/empresas" className="col-xl-4 col-lg-2 col-md-4 col-sm-4">
                <HomeSingleFeature title="Empresas" />
              </NavLink>
              <NavLink to="/productores" className="col-xl-4 col-lg-2 col-md-4 col-sm-4">
                <HomeSingleFeature title="Productores" />
              </NavLink>
              <NavLink to="/empresas" className="col-xl-4 col-lg-2 col-md-4 col-sm-4">
              <HomeSingleFeature last={true} title="Personas" />
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeFeatures;
