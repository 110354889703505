import React from "react";
import { FaLinkedin, FaTwitter, FaVimeoV } from "react-icons/fa";
import { Link } from "react-router-dom";

const SingleTeam = ({ image, name, title, linkedin }) => {
  return (
    <>
      <div className="col-xl-3 col-lg-4 col-md-6 mb-3">
        <div className="team__item p-relative text-center fix h-100">
          <div className="team__thumb mb-25">
            <img src={`assets/img/team/${image}.jpg`} alt="team" />
            <div className="team__info text-start">
              <h3>
                <Link to="/teamDetails">{name}</Link>
              </h3>
              <span>{title}</span>
            </div>
            <div className="team__social theme-social">
              <ul>
                <li>
                  <a href={linkedin}>
                    <i>
                      {" "}
                      <FaLinkedin />{" "}
                    </i>
                    <i>
                      {" "}
                      <FaLinkedin />{" "}
                    </i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="team__content">
            <h3>
              <Link to="/teamDetails">{name}</Link>
            </h3>
            <span>{title}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleTeam;
