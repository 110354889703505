import React from 'react';
import SingleCount from './SingleCount/SingleCount';

const HomeCounterArea = () => {
   return (
      <>
         <section className="counter__area pb-100">
            <div className="container">
               <div className="counter__inner white-bg wow fadeInUp" data-wow-delay=".1s">
                  <div className="row">

                     <SingleCount counter={182} title="PRODUCTORES" color={'#D31F47'}/>
                     <SingleCount counter={+97000} title="ASEGURADOS" />
                     <SingleCount counter={20000} title="PÓLIZAS EMITIDAS" />

                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeCounterArea;