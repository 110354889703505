import React, { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.message) return;
    window.open(
      `https://wa.me/5492235733617?text=Nombre:%20${formData.name}%0AMail:%20${
        formData.email
      }%0AMensaje:%20${formData.message.replace(" ", "%20")}`,
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <div
      className="contact__wrapper mx-auto d-md-flex justify-content-between"
      id="contactForm"
    >
      <div className="contact__info mr-100">
        <h3>Contactanos</h3>
        <ul>
          <li>
            <h4>Dirección</h4>
            <p>Primera Junta 3149 - 2° A <br/> Mar del Plata (7600)</p>
          </li>
          <li>
            <h4>Teléfono</h4>
            <p>
              <a href="tel:+5492235733617">+54 9 223 573-3617</a>
            </p>
          </li>
          <li>
            <h4>Email</h4>
            <p>
              <a href="mailto:info@gfirenze.com.ar">
                info@gfirenze.com.ar
              </a>
            </p>
          </li>
        </ul>
      </div>
      <div className="contact__form">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Nombre"
            id="name"
            onChange={handleInputChange}
          />
          <input
            type="email"
            placeholder="Email"
            id="email"
            onChange={handleInputChange}
          />
          <textarea
            placeholder="Mensaje"
            id="message"
            onChange={handleInputChange}
          ></textarea>
          <button type="submit" className="z-btn ">
            Enviar Mensaje
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
