import "./CircleItem.scss";


const CircleItem = ({ item }) => {
  return (
    <>
      <div className="circle-item d-flex align-items-center">
        <div className="circle rounded-circle d-flex justify-content-center align-items-center">
          {/* <img className="icon" src={'./assets/' + item + '.svg'}></img> */}
          <img className="icon" src={'assets/icons/' + item.toLowerCase() + '.svg'} alt={item}></img>
        </div>
        <p className="text mt-20" dangerouslySetInnerHTML={{ __html: item.replace(' ', '<br>')}}></p>
      </div>
    </>
  );
};

export default CircleItem;
