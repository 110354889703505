import React from "react";
import HomeOneHeader from "./HomeOneHeader/HomeOneHeader";
import HomeHeroSlider from "./HomeHeroArea/HomeHeroSlider";
import Footer from "./Footer/Footer.js";
import PageHelmet from "../../components/shared/PageHelmet";
import CircleItems from "./CircleItems/CircleItems";
import ContactForm from "./HomeContact/ContactForm/ContactForm";

const People = () => {
  const items = [
    "auto", "hogar", "moto", "accidentes personales"
  ];
  return (
    <>
      <PageHelmet pageTitle="Home Page" />
      <HomeOneHeader />
      <HomeHeroSlider title="Seguros para individuos" subtitle="Cuidamos lo que más te importa" image="home_slider_people" height="650"/>
      <CircleItems items={items}/>
      <ContactForm />
      <Footer />
    </>
  );
};

export default People;
