import React from "react";

const SingleService = ({ icon, title, desc }) => {
  return (
    <>
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <div className="services__item mb-90">
          <div className="services__icon mb-35">
            <img src={`assets/${icon}.png`} alt="services" />
          </div>
          <div className="services__content">
            <h3>{title}</h3>
            <p>
              {desc}.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleService;
