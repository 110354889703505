import React from 'react';
import SingleService from './SingleService/SingleService';

const HomeServices = () => {
   return (
      <>
         <section className="services__area pt-80 pb-80">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 text-center mb-90 wow fadeInUp" data-wow-delay=".2s">
                        <span>Firenze</span>
                        <h2>Unidades de negocio</h2>
                     </div>
                  </div>
               </div>
               <div className="row justify-content-center">

                  <SingleService icon="vidayretiro" title="VIDA Y RETIRO" desc="Resguardamos tu mañana y jubilación con seguros de vida y soluciones para un futuro financiero estable" />
                  <SingleService icon="art" title="ART" desc="Cuidamos a tu equipo con cobertura integral, minimizando riesgos laborales y protegiendo la salud de tus trabajadores"/>
                  <SingleService icon="viajes" title="VIAJES" desc="Coberturas completas para viajes, asegurando aventuras sin preocupaciones y asistencia global en cualquier momento"/>
                  <SingleService icon="serviciosfinancieros" title="SERVICIOS FINANCIEROS" desc="Potenciamos tu economía: asesoramiento y productos financieros para alcanzar metas, asegurar bienestar y optimizar tus recursos"/>
                  {/* <SingleService icon="6" title="Cord Processing" />
                  <SingleService icon="7" title="Development" />
                  <SingleService icon="8" title="Financial audit" /> */}

               </div>
            </div>
         </section>
      </>
   );
};

export default HomeServices;