import React from "react";
import { NavLink } from "react-router-dom";
import "./_footer.scss";

const Footer = () => {
  return (
    <footer className="container d-flex flex-column align-items-center justify-content-center pt-90 pb-70">
      <img src="assets/img/homefour/logo/logo-red.svg" alt="logo"></img>
      <ul className="footer-navigation d-flex align-items-center mt-15">
        <li>
          <NavLink to="/">Inicio</NavLink>
        </li>
        <li>
          <NavLink to="/empresas">Empresas</NavLink>
        </li>
        <li>
          <NavLink to="/productores">Productores</NavLink>
        </li>
        <li>
          <NavLink to="/personas">Personas</NavLink>
        </li>
      </ul>
      <div className="zaphify d-flex flex-column align-items-center justify-content-center mt-50">
        <p className="mt-40">Developed by</p>
        <img className="mt-10" src="assets/img/homefour/logo/zaphify.svg" alt="logo"></img>
      </div>
    </footer>
  );
};

export default Footer;
