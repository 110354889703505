import React from "react";
import HomeOneHeader from "./HomeOneHeader/HomeOneHeader";
import HomeHeroSlider from "./HomeHeroArea/HomeHeroSlider";
import Footer from "./Footer/Footer.js";
import PageHelmet from "../../components/shared/PageHelmet";
import ContactForm from "./HomeContact/ContactForm/ContactForm";
import Products from "./Products/Products";

const Productors = () => {
  return (
    <>
      <PageHelmet pageTitle="Home Page" />
      <HomeOneHeader />
      <HomeHeroSlider
        title="Productores"
        subtitle="Potenciá tu carrera. Unite a nosotros y experimentá la diferencia de trabajar con un equipo comprometido con tu éxito."
        image="home_slider_products"
        height="650"
      />
      <Products />
      <ContactForm />
      {/*<HomeFeatures/>
            <HomeServices/>
            <HomeCounterArea/>
            <HomeAbout/> */}
      {/* <HomeCapabilities/> */}
      {/* <HomeTeams /> */}
      {/* <CommonCtaArea/>
            <HomePricing/> */}
      {/* <HomeBrands /> */}
      {/* <HomeContact /> */}
      <Footer />
    </>
  );
};

export default Productors;
