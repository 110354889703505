import { FaCheck } from 'react-icons/fa';
import "./Products.scss";

const Products = () => {
  return (
    <section className="products-container d-flex mb-100">
      <div className="image">
        <img src="assets/img/products/products.png" alt='product' />
      </div>
      <div className="products">
        <p className="subtitle">Productor</p>
        <h1 className="title mb-70">Potenciá tu desarrollo para brindar un servicio de calidad.</h1>
        <ul>
          <li>
            <span>
              <i>
                {" "}
                <FaCheck />{" "}
              </i>
              Acceso a /Trabajar en conjunto con compañías líderes del mercado, lo que te permitirá ofrecer una variedad de opciones a tus clientes.
            </span>
          </li>
          <li>
            <span>
              <i>
                {" "}
                <FaCheck />{" "}
              </i>
              Soporte en la gestión de pólizas
            </span>
          </li>
          <li>
            <span>
              <i>
                {" "}
                <FaCheck />{" "}
              </i>
              Soporte en la gestión de siniestros.
            </span>
          </li>
          <li>
            <span>
              <i>
                {" "}
                <FaCheck />{" "}
              </i>
              Asesoramiento técnico y legal.
            </span>
          </li><li>
            <span>
              <i>
                {" "}
                <FaCheck />{" "}
              </i>
              Capacitaciones continuas con especialistas de cada área.
            </span>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Products;
