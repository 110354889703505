const HomeBrands = () => {
  const imagenes = [
    { id: 1, src: "logo-allianz", alt: "Allianz" },
    { id: 2, src: "logo-asociart", alt: "Asociart" },
    { id: 3, src: "logo-berkley", alt: "Berkley" },
    { id: 4, src: "logo-experta", alt: "Experta" },
    { id: 5, src: "logo-galeno", alt: "Galeno" },
    { id: 6, src: "logo-provincia", alt: "Provincia" },
    { id: 7, src: "logo-rus", alt: "Rus" },
    { id: 8, src: "logo-rivadavia", alt: "Rivadavia" },
    { id: 9, src: "logo-triunfo", alt: "Triunfo" },
    { id: 10, src: "logo-zurich", alt: "Zurich" },
    { id: 11, src: "logo-coris", alt: "Coris" },
    { id: 12, src: "logo-gruposancristobal", alt: "Grupo San Cristobal" },
  ];

  const settings = {
    autoplay: false,
    autoplaySpeed: 10000,
    dots: false,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="brand__area p-relative pt-100 pb-50">
        {/* <div className="brand__shape">
               <img className="square" src="assets/img/icon/brand/square.png" alt="" />
               <img className="circle" src="assets/img/icon/brand/circle.png" alt="" />
               <img className="circle-2" src="assets/img/icon/brand/circle-2.png" alt="" />
               <img className="triangle" src="assets/img/icon/brand/trianlge.png" alt="" />
            </div> */}
        <div className="container p-relative">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8">
              <div className="text-center section__title">
                {/* <span>Lorem ipsum</span> */}
              </div>
              <div
                className="text-center section__title section__title-3 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <h2>Respaldo</h2>
              </div>
              <div
                className="text-center brand__subtitle mb-90 wow fadeInUp"
                data-wow-delay=".4s"
              >
                <p>
                  Contamos con el respaldo de las compañías líderes en el
                  mercado para garantizar las mejores oportunidades de negocio
                  para cada área específica.
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              {imagenes.map((imagen) => (
                <div key={imagen.id} className="col-md-3 text-center" style={{ marginBottom: '40px' }}>
                  <img
                    src={`assets/img/brand/${imagen.src}.png`}
                    alt={imagen.alt}
                    className="img-fluid"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              ))}
            </div>
          </div>
          {/* <div className="row">
            <Slider className="brand-active" {...settings}>
              <SingleBrandItem img="allianz" />
              <SingleBrandItem img="asociart" />
              <SingleBrandItem img="berkley" />
              <SingleBrandItem img="experta" />
              <SingleBrandItem img="galeno" />
              <SingleBrandItem img="provincia" />
              <SingleBrandItem img="rus" />
              <SingleBrandItem img="rivadavia" />
              <SingleBrandItem img="triunfo" />
              <SingleBrandItem img="zurich" />
              <SingleBrandItem img="coris" />
              <SingleBrandItem img="gruposancristobal" />
            </Slider>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default HomeBrands;
