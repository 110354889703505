import React from "react";
import "./HomeHeroSlider.scss";
import { Link } from "react-router-dom";

const HomeHeroArea = ({ title, subtitle, image, height }) => {
  const scrollToElement = () => {
    const contactForm = document.getElementById("contactForm");
    const header = document.getElementById("header__sticky");

    if (contactForm && header) {
      // Obtén la altura del header.
      const headerHeight = header.clientHeight;
  
      // Obtén la posición del elemento a la que deseas desplazarte.
      const elementPosition = contactForm.getBoundingClientRect().top;
  
      // Calcula la posición de desplazamiento restando la altura del header.
      const scrollToPosition = elementPosition + window.scrollY - headerHeight;
  
      // Utiliza scrollTo para desplazarte a la nueva posición.
      window.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
      {/* <VideoPopup videoId="yJg-Y5byMMw" /> */}
      <section className="slider__area">
        {/*<Slider className="slider-active" {...settings}>
          {homeSliderData.map((slider, index) => { */}
        {/* return ( */}
        <div
          style={{ height: `${height}px` }}
          className={`single-slider d-flex align-items-center ${image}`}
        >
          <div className="container h1_slider_wrapper">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-10 col-sm-10">
                <div className="slider__content">
                  {/* <span>We’ve added a new exciting feature in v2.0.</span> */}
                  <h1 className="title">{title}</h1>
                  {subtitle ? <p className="subtitle">{subtitle}</p> : null}

                  <div className="slider__btn mt-40">
                    <Link
                      to=""
                      className="z-btn z-btn-transparent"
                      onClick={scrollToElement}
                    >
                      Contactanos
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-3 col-lg-3 col-md-2 col-sm-2">
                      <div className="slider__play">
                        <button
                          onClick={() => setIsVideoOpen(true)}
                          className="slider__play-btn"
                        >
                          <i>
                            {" "}
                            <FiPlay />{" "}
                          </i>
                        </button>
                      </div>
                    </div> */}
            </div>
          </div>
        </div>
        {/* ); */}
        {/* })} */}
        {/* </Slider> */}
      </section>
    </>
  );
};

export default HomeHeroArea;
